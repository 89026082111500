import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BuyerOrientationDialog } from '../../../Components/DealRoom/Dialogs/BuyerOrientationDialog';
import { UserDetails } from '../../../types/BuyerOrientationSurveyTypes';
import { isBuyer } from '../../../utils/buyerDecisionSiteUtils';
import { useDealRoom } from '../../useDealRoom';
import { useLocalStorageState } from '../../useLocalStorageState';
import { useUserProfile } from '../../useProfile';

// Props type definition for the hook
type UseDealRoomBuyerOrientationDialogProps = {
  organizationSlug?: string;
  dealRoomId?: number;
};

// Hook to manage the buyer orientation dialog display logic
export const useDealRoomBuyerOrientationDialog = ({
  organizationSlug,
  dealRoomId,
}: UseDealRoomBuyerOrientationDialogProps) => {
  // Get user profile and deal room data
  const { userId, user } = useUserProfile();
  const [searchParams] = useSearchParams();
  const {
    dealRoom,
    dealRoomRole,
    refetch: refetchDealRoomData,
  } = useDealRoom(organizationSlug, dealRoomId);

  // Track when user last skipped the orientation in local storage
  const [buyerOrientationSkippedAt, setBuyerOrientationSkippedAt] =
    useLocalStorageState<string | undefined>(
      `${organizationSlug}-${dealRoomId}-${user?.id}-buyerSurveySkippedAt`,
      undefined,
    );

  // Find current user's details from deal room contacts
  const currentUserDetails = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return null;
    return (
      (dealRoom?.contacts?.find(
        (contact) => contact.userId === userId || contact.email === user?.email,
      ) as UserDetails) || null
    );
  }, [dealRoom?.contacts, user?.email, userId]);

  // Check if user has completed all required survey sections:
  // - Assistance preference
  // - Consumption preference
  // - Communication preference
  // - At least one priority selected
  const isSurveyCompleted = useMemo(() => {
    if (!currentUserDetails) return false;

    const {
      assistancePreference,
      consumptionPreference,
      communicationPreference,
      priorities,
    } = currentUserDetails;
    return !!(
      assistancePreference &&
      consumptionPreference &&
      communicationPreference &&
      priorities?.length
    );
  }, [currentUserDetails]);

  // Determine if orientation survey should be skipped based on last skip time
  const skipOrientationSurvey = useMemo(() => {
    if (!buyerOrientationSkippedAt) {
      return undefined;
    }

    try {
      const skippedAt = DateTime.fromISO(buyerOrientationSkippedAt);

      return (
        skippedAt.isValid && skippedAt >= DateTime.now().minus({ days: 1 })
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [buyerOrientationSkippedAt]);

  // Get list of deal room contacts excluding current user
  const dealRoomContacts = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return [];

    return dealRoom.contacts
      .filter(
        (contact) => contact.userId !== userId && contact.email !== user?.email,
      )
      .map((contact) => contact.name || 'N/A');
  }, [dealRoom?.contacts, user?.email, userId]);

  // Handle dialog dismissal by updating skip timestamp
  const handleDismiss = useCallback(() => {
    setBuyerOrientationSkippedAt(DateTime.now().toISO());
  }, [setBuyerOrientationSkippedAt]);

  // Check if user has a buyer role
  const hasUserBuyerRole = useMemo(() => isBuyer(dealRoomRole), [dealRoomRole]);

  // Return null if conditions for showing dialog aren't met
  if (
    !user ||
    !dealRoom ||
    ((!hasUserBuyerRole || skipOrientationSurvey || isSurveyCompleted) &&
      !searchParams.has('showBuyerOrientation'))
  ) {
    return null;
  }

  // Return the buyer orientation dialog with required props
  return (
    <BuyerOrientationDialog
      ownerAvatarUrl={
        dealRoom?.owner?.avatarFileUrl ||
        dealRoom?.owner?.avatarUrl ||
        undefined
      }
      handleDismiss={handleDismiss}
      ownerName={
        dealRoom?.owner?.firstName || dealRoom?.owner?.name || undefined
      }
      userName={user?.firstName || user?.name || undefined}
      userEmail={user?.email}
      dealRoomContacts={dealRoomContacts}
      currentUserDetails={currentUserDetails as UserDetails}
      organizationSlug={organizationSlug}
      dealRoomId={dealRoomId}
      userRole={dealRoomRole}
      refetchDealRoomData={refetchDealRoomData}
    />
  );
};
