import { useAuth0 } from '@auth0/auth0-react';
import { mergeStyles, Spinner } from '@fluentui/react';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import randomColor from 'randomcolor';
import { useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Outlet } from 'react-router';
import * as Y from 'yjs';
import {
  isAxiosErrorResponse,
  isForbiddenError,
} from '../../Helpers/AxiosHelpers';
import { useCollabProvider } from '../../Hooks/useCollabProvider';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { useDealRoomId } from '../../Hooks/useDealRoomId';
import { useNavigate } from '../../Hooks/useNavigate';
import { useOrganization } from '../../Hooks/useOrganization';
import { useOrganizationSlug } from '../../Hooks/useOrganizationSlug';
import { useUserProfile } from '../../Hooks/useProfile';
import { OrganizationDealRoomQuery } from '../../QueryNames';
import OrganizationForbidden from '../Organization/OrganizationForbidden';
import ResourceNotFound from '../ResourceNotFound';
import { DealRoomBuyersSidebar } from './Components/DealRoomBuyersSidebar';
import { DealRoomBuyersNavbar } from './Components/DealRoomBuyersNavbar';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { useSearchParams } from 'react-router-dom';
import { useDealRoomBuyerOrientationDialog } from '../../Hooks/Modals/DealRoom/useDealRoomBuyerOrientationDialog';

export const DealRoomBuyersRoot = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { user } = useAuth0();
  const { user: mfUser } = useUserProfile();

  const { role } = useOrganization();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();

  const client = useQueryClient();

  const { isFetched: organizationFetched, hasEntitlement } = useOrganization();

  const {
    dealRoom,
    isLoading: dealRoomLoading,
    isFetched: dealRoomFetched,
    error: dealRoomError,
  } = useDealRoom(organizationSlug, dealRoomId);

  const dealRoomBuyerOrientationSurvey = useDealRoomBuyerOrientationDialog({
    organizationSlug,
    dealRoomId,
  });

  const showReturnButton = useMemo(
    () => !!searchParams.get('buyerView') && role !== 'GUEST',
    [searchParams, role],
  );

  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, dealRoomId]);

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  const onMessage = useCallback(
    (messageType: number) => {
      switch (messageType) {
        case WS_MESSAGE.REFRESH_DEALROOM:
        case WS_MESSAGE.REFRESH_DEALROOM_ARTIFACTS:
        case WS_MESSAGE.REFRESH_DEALROOM_ACTION_ITEMS:
          client.invalidateQueries(
            OrganizationDealRoomQuery(organizationSlug, dealRoomId),
          );
          return true;
        default:
          return false;
      }
    },
    [client, dealRoomId, organizationSlug],
  );

  const { provider: _provider } = useCollabProvider({
    providerName: 'DealRoom',
    documentName:
      organizationSlug && dealRoomId
        ? `DealRoom__${organizationSlug}__${dealRoomId}`
        : '',
    ydoc,
    color,
    email: mfUser?.email || user!.email!,
    name: mfUser?.name || user!.name,
    picture: mfUser?.avatarFileUrl || mfUser?.avatarUrl || user?.picture,
    onMessage,
  });

  useEffect(() => {
    if (!organizationSlug) {
      navigate(`/`);
      return;
    } else if (
      !dealRoomId ||
      (organizationFetched && !hasEntitlement('DEAL_ROOM'))
    ) {
      navigate(`/organization/${organizationSlug}`);
      return;
    }
  }, [
    organizationSlug,
    hasEntitlement,
    navigate,
    dealRoomId,
    organizationFetched,
  ]);

  const baseContainerStyle = mergeStyles({
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    backgroundColor: DEALROOMS_COLORS.neutralLighter,

    '.sidebar-container': {},

    '.content-container': {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',

      '.content-header': {},

      '.content': {
        flex: '1',
        overflow: 'auto',
      },
    },
  });

  if (!organizationSlug || !dealRoomId) {
    return null;
  }

  if (dealRoomLoading && !dealRoomFetched) {
    return <Spinner />;
  }

  if (isForbiddenError(dealRoomError)) {
    return <OrganizationForbidden />;
  }

  if (isAxiosErrorResponse(dealRoomError, 404)) {
    return <ResourceNotFound resourceType="Deal Room" />;
  }

  if (isAxiosErrorResponse(dealRoomError)) {
    return null;
  }

  return (
    <div className={baseContainerStyle}>
      <div className="sidebar-container">
        <DealRoomBuyersSidebar
          dealRoomId={dealRoomId}
          organizationSlug={organizationSlug}
        />
      </div>
      <div className="content-container">
        <div className="content-header">
          <DealRoomBuyersNavbar
            organizationSlug={organizationSlug}
            dealRoomName={dealRoom?.name || 'Decision Site'}
            dealRoomOwner={dealRoom?.owner?.name || 'Unknown User'}
            showReturnToSellerViewButton={
              !import.meta.env.DEV && showReturnButton
            }
          />
        </div>
        <div className="content">
          <Outlet />
        </div>
      </div>
      {dealRoomBuyerOrientationSurvey}
    </div>
  );
};
