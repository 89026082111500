import { useAuth0 } from '@auth0/auth0-react';
import {
  ContextualMenu,
  ContextualMenuItemType,
  DirectionalHint,
  FontSizes,
  FontWeights,
  IContextualMenuItem,
  IContextualMenuStyles,
  IPersonaSharedProps,
  mergeStyles,
  NeutralColors,
  Persona,
  PersonaSize,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import React, { useCallback, useMemo, useRef } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useUserProfile } from '../../../Hooks/useProfile';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { DealRoomPrimaryButton } from './DealRoomButton';

type DealRoomNavbarProps = {
  organizationSlug: string;
  dealRoomName: string;
  dealRoomOwner: string;
  showReturnToSellerViewButton: boolean;
};

const menuStyles = {
  root: {
    backgroundColor: DEALROOMS_COLORS.themePrimary,
    '.ms-ContextualMenu-header': {
      height: '1rem !important',
      paddingTop: '.25rem',
      fontSize: FontSizes.mini,
      textTransform: 'uppercase',
      fontWeight: FontWeights.semibold,
      color: DEALROOMS_COLORS.white,

      i: {
        display: 'none',
      },
    },
    '.ms-ContextualMenu-divider': {
      backgroundColor: NeutralColors.gray150,
    },
    '.ms-ContextualMenu-itemText': {
      color: DEALROOMS_COLORS.white,
    },
    button: {
      transition: '.3s ease-in-out',
      height: '2rem',
      ':hover': {
        backgroundColor: MEETINGFLOW_COLORS.purpleLight,
      },
      i: {
        transition: '.3s ease-in-out',
        color: DEALROOMS_COLORS.white,
      },
    },
    'button.is-expanded': {
      color: DEALROOMS_COLORS.white,
      backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
    },
    'button.is-expanded i': {
      color: DEALROOMS_COLORS.white,
    },
    'button:hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleDark,

      i: {
        color: `${DEALROOMS_COLORS.white} !important`,
      },
    },
    a: {
      transition: '.3s ease-in-out',
      height: '2rem',
      ':hover': {
        backgroundColor: MEETINGFLOW_COLORS.purpleLight,
        textDecoration: 'none',
      },
      i: {
        transition: '.3s ease-in-out',
      },
    },
    'a.is-expanded': {
      color: DEALROOMS_COLORS.white,
      backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
    },
    'a.is-expanded i': {
      color: DEALROOMS_COLORS.white,
    },
    'a:hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleDark,

      i: {
        color: `${DEALROOMS_COLORS.white} !important`,
      },
    },
  },
} as Partial<IContextualMenuStyles>;

export const DealRoomBuyersNavbar = ({
  organizationSlug,
  dealRoomName,
  dealRoomOwner,
  showReturnToSellerViewButton,
}: DealRoomNavbarProps) => {
  const [
    showUserMenu,
    { setTrue: setShowUserMenu, setFalse: setNotShowUserMenu },
  ] = useBoolean(false);

  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const userRef = useRef(null);
  const { user: mfUser } = useUserProfile();

  const onShowUserMenu = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault(); // don't navigate
      setShowUserMenu();
    },
    [setShowUserMenu],
  );

  const onHideUserMenu = useCallback(
    () => setNotShowUserMenu(),
    [setNotShowUserMenu],
  );

  const userPersona: IPersonaSharedProps = useMemo(
    () => ({
      text: mfUser?.name || user?.name,
      imageUrl: mfUser?.avatarFileUrl || mfUser?.avatarUrl || user?.picture,
    }),
    [
      mfUser?.avatarFileUrl,
      mfUser?.avatarUrl,
      mfUser?.name,
      user?.name,
      user?.picture,
    ],
  );

  const navbarStyles = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: DEALROOMS_COLORS.white,
    padding: '1rem',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '1rem',
    borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,

    '.navbar-title': {
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '24px',
      color: DEALROOMS_COLORS.themePrimary,
      paddingLeft: '1rem',
    },

    '.navbar-actions': {
      display: 'flex',
      alignItems: 'center',
      gap: '40px',

      '.action-button': {
        padding: '0.5rem 1rem',
        '.ms-Button-label': {
          fontSize: '15px',
          lineHeight: '24px',
          fontWeight: '400',
        },
      },
    },
  });

  const userMenuItems: IContextualMenuItem[] = [
    {
      key: 'profile',
      text: 'Your Profile',
      iconProps: {
        iconName: 'AccountBrowser',
      },
      onClick: () => navigate('/profile'),
    },
    {
      key: 'invites',
      text: 'Your Workspace Invites',
      iconProps: {
        iconName: 'ChatInviteFriend',
      },
      onClick: () => navigate('/invites'),
    },
    {
      key: 'divider-1',
      text: 'Divider',
      itemType: ContextualMenuItemType.Divider,
    },
    import.meta.env.DEV
      ? {
          key: 'copy token',
          text: 'Copy bearer token',
          iconProps: {
            iconName: 'Copy',
          },
          onClick: () => {
            getAccessTokenSilently().then((token) => {
              navigator?.clipboard
                ?.writeText(token)
                .then(() => toast(`Bearer token copied to clipboard`));
            });
          },
        }
      : undefined,
    {
      key: 'divider-2',
      text: 'Divider',
      itemType: ContextualMenuItemType.Divider,
    },
    {
      key: 'sign-out',
      text: 'Sign out',
      iconProps: {
        iconName: 'SignOut',
      },
      onClick: () => {
        navigate('/logout');
      },
    },
  ].filter(Truthy) as IContextualMenuItem[];

  return (
    <div className={navbarStyles}>
      <div className="navbar-title">{dealRoomName}</div>
      <div className="navbar-actions">
        {showReturnToSellerViewButton && (
          <DealRoomPrimaryButton
            customClasses="action-button"
            text="Return to seller's view"
            onClick={() => navigate(location.pathname)}
          />
        )}
        <DealRoomPrimaryButton
          customClasses="action-button"
          iconProps={{ iconName: 'CalendarWorkWeek' }}
          text={`Book a meeting with ${dealRoomOwner}`}
          onClick={() => {}}
        />
        <div
          id="meetingflow-header-user-menu"
          className="app-menu app-user-menu"
          ref={userRef}
          onClick={onShowUserMenu}
        >
          <Persona
            {...userPersona}
            size={PersonaSize.size40}
            hidePersonaDetails={true}
          />
          <ContextualMenu
            items={userMenuItems}
            styles={menuStyles}
            hidden={!showUserMenu}
            target={userRef}
            onItemClick={onHideUserMenu}
            onDismiss={onHideUserMenu}
            className="app-contextual-menu"
            gapSpace={6}
            alignTargetEdge
            directionalHint={DirectionalHint.bottomRightEdge}
          />
        </div>
      </div>
    </div>
  );
};
