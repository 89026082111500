import { useAuth0 } from '@auth0/auth0-react';
import { mergeStyleSets, Spinner, Stack, Text } from '@fluentui/react';
import { capitalize } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useUserProfile } from '../../Hooks/useProfile';
import { useTitle } from '../../Hooks/useTitle';
import { OrganizationsQuery } from '../../QueryNames';
import { OrganizationsApiClient } from '../../Services/NetworkCommon';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { OrganizationPersona } from '../OrganizationPersona';
import { DecisionSiteHomeContainer } from './DecisionSiteHomeContainer';
import { DecisionSiteHomePlaceholder } from './DecisionSiteHomePlaceholder';

export const DecisionSiteHome = () => {
  const { user: auth0User, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { user } = useUserProfile();

  useTitle('Home');

  const styles = mergeStyleSets({
    content: {
      gap: '1rem',
      overflow: 'auto',
      width: '100%',
    },

    sectionTitle: {
      color: DEALROOMS_COLORS.themePrimary,
      fontWeight: 400,
    },

    workspacesContainer: {
      flexWrap: 'wrap',
      gap: '2rem',
      padding: '1rem',
    },

    workspaceItem: {
      minWidth: '150px',
      position: 'relative',
      padding: '1rem',
      margin: '0',
      borderRadius: '4px',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      boxShadow: '0 2px 2px 0 #00000005',

      ':hover': {
        cursor: 'pointer',
        backgroundColor: DEALROOMS_COLORS.neutralLighter,
      },
    },
  });

  const headerTitle = useMemo(() => {
    const generalTitle = `Welcome to ${import.meta.env.VITE_SITE_NAME}`;
    const userName =
      user?.firstName ||
      user?.name?.split(' ')[0] ||
      auth0User?.nickname ||
      auth0User?.name?.split(' ')[0];

    return userName ? `${generalTitle}, ${capitalize(userName)}` : generalTitle;
  }, [user, auth0User]);

  const { data: orgData, isLoading: orgDataIsLoading } = useQuery(
    OrganizationsQuery,
    async () => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.listOrganizations(
        { entitlement: 'DEAL_ROOM' },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  useEffect(() => {
    if (orgData?.data?.length === 1) {
      navigate(`/organization/${orgData.data[0].slug}`);
      return;
    }
  }, [navigate, orgData?.data]);

  return (
    <DecisionSiteHomeContainer
      title={headerTitle}
      description={`To get started using ${import.meta.env.VITE_SITE_NAME}, select one of your workspaces.`}
    >
      <Stack className={styles.content}>
        {orgDataIsLoading ? (
          <Spinner />
        ) : orgData?.data?.length ? (
          <>
            <Text className={styles.sectionTitle} block variant="large">
              Your Workspaces
            </Text>
            <Stack className={styles.workspacesContainer} horizontal>
              {orgData?.data?.map((organization) => (
                <Stack.Item
                  grow={1}
                  key={organization.id}
                  onClick={() => navigate(`/organization/${organization.slug}`)}
                  className={styles.workspaceItem}
                >
                  <OrganizationPersona organization={organization} />
                </Stack.Item>
              ))}
            </Stack>
          </>
        ) : (
          <DecisionSiteHomePlaceholder />
        )}
      </Stack>
    </DecisionSiteHomeContainer>
  );
};

export default DecisionSiteHome;
