import { IconButton, mergeStyles, Stack } from '@fluentui/react';
import classNames from 'classnames';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { matchPath, useLocation } from 'react-router';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from '../../../Hooks/useNavigate';
import { AugmentLogo } from './AugmentLogo';
import { useBoolean } from '@fluentui/react-hooks';

export type DealRoomBuyersSidebarProps = {
  organizationSlug: string;
  dealRoomId: number;
};

type SidebarLink = {
  label: string;
  path: string;
  iconName: string;
};

const leftSidebarLinks: SidebarLink[] = [
  {
    label: 'Overview',
    path: 'overview',
    iconName: 'GridViewMedium',
  },
  {
    label: 'Artifacts',
    path: 'artifacts',
    iconName: 'FabricFolder',
  },
  // {
  //   label: 'Journey',
  //   path: 'journey',
  //   iconName: 'Clock',
  // },
  {
    label: 'Mutual Plan',
    path: 'mutual-action-plan',
    iconName: 'TeamFavorite',
  },
  {
    label: 'Settings',
    path: 'settings',
    iconName: 'Settings',
  },
];

export const DealRoomBuyersSidebar = ({
  organizationSlug,
  dealRoomId,
}: DealRoomBuyersSidebarProps) => {
  const [
    isSidebarCollapsed,
    {
      setTrue: setSidebarCollapsed,
      setFalse: setSidebarNotCollapsed,
      toggle: toggleSidebarCollapse,
    },
  ] = useBoolean(true);

  const location = useLocation();

  const navigate = useNavigate();

  const activeTab = useMemo(() => {
    if (
      matchPath(
        `/organization/:organizationSlug/dealroom/:dealRoomId/artifacts`,
        location.pathname,
      )
    ) {
      return 'artifacts';
    }

    if (
      matchPath(
        `/organization/:organizationSlug/dealroom/:dealRoomId/journey`,
        location.pathname,
      )
    ) {
      return 'journey';
    }

    if (
      matchPath(
        `/organization/:organizationSlug/dealroom/:dealRoomId/mutual-action-plan`,
        location.pathname,
      )
    ) {
      return 'mutual-action-plan';
    }

    if (
      matchPath(
        `/organization/:organizationSlug/dealroom/:dealRoomId/settings`,
        location.pathname,
      )
    ) {
      return 'settings';
    }

    return 'overview';
  }, [location.pathname]);

  const handleClickSidebarLink = useCallback(
    (path: string) => {
      navigate(
        `/organization/${organizationSlug}/dealroom/${dealRoomId}/${path}`,
        { preserveQuery: true },
      );
    },
    [navigate, organizationSlug, dealRoomId],
  );

  const handleClickSidebarBackButton = useCallback(() => {
    navigate(`/organization/${organizationSlug}`);
  }, [navigate, organizationSlug]);

  const styles = mergeStyles({
    backgroundColor: DEALROOMS_COLORS.themePrimary,
    willChange: 'width',
    color: DEALROOMS_COLORS.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'width 0.5s linear',
    boxSizing: 'border-box',
    width: isSidebarCollapsed ? '89px' : '164px',
    height: '100%',
    padding: '1.5rem',
    gap: '3.5rem',

    '.left-sidebar-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      width: 'fit-content',

      '.left-sidebar-header-button': {
        width: '24px',
        height: '24px',
        fontSize: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        transition: 'left 0.5s linear',
        left: isSidebarCollapsed ? 'calc(100% + 10px)' : 'calc(100% + 58px)',
        backgroundColor: DEALROOMS_COLORS.themePrimary,
        color: DEALROOMS_COLORS.themeSecondary,
        i: {
          fontSize: '10px',
        },
        ':hover': {
          backgroundColor: DEALROOMS_COLORS.sidebarHoverTextPrimary,
          color: DEALROOMS_COLORS.sidebarTextPrimary,
        },
      },
    },

    '.left-sidebar-links': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: '20px',

      '.left-sidebar-link': {
        width: '100%',
        fontSize: '16px',
        color: DEALROOMS_COLORS.sidebarTextSecondary,
        padding: '12px 8px',
        height: 'auto',
        borderRadius: '4px',
        justifyContent: 'flex-start',

        i: {
          margin: 0,
          padding: 0,
        },

        'span.ms-Button-flexContainer': {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '8px',
          paddingLeft: '4px',
        },

        ':hover': {
          backgroundColor: DEALROOMS_COLORS.sidebarHoverTextPrimary,
          color: DEALROOMS_COLORS.sidebarTextPrimary,
        },

        '.left-sidebar-link-label': {
          fontSize: '13px',
          whiteSpace: 'nowrap',
          opacity: 0,
          transition: 'opacity .1s linear',
          visibility: 'hidden',
        },

        '.left-sidebar-link-label-visible': {
          visibility: 'visible',
          opacity: 1,
          transition: 'visibility .1s linear .2s, opacity .1s linear .2s',
        },
      },
    },

    '.left-sidebar-back-icon': {
      width: 'fit-content',
      color: DEALROOMS_COLORS.sidebarTextSecondary,
      backgroundColor: DEALROOMS_COLORS.themePrimary,
      padding: '0 2px',
      margin: '0',

      span: {
        justifyContent: 'flex-start',
      },

      i: {
        fontSize: '16px',
      },

      ':hover': {
        backgroundColor: DEALROOMS_COLORS.sidebarHoverTextPrimary,
        color: DEALROOMS_COLORS.sidebarTextPrimary,
      },
    },

    '.active-link': {
      color: `${DEALROOMS_COLORS.sidebarTextPrimary} !important`,
      backgroundColor: `${DEALROOMS_COLORS.sidebarHoverTextPrimary} !important`,
    },
  });

  const renderSidebarLinks = useMemo(
    () =>
      leftSidebarLinks.map((sidebarLink) => (
        <IconButton
          key={sidebarLink.path}
          className={classNames('left-sidebar-link', {
            'active-link': activeTab === sidebarLink.path,
          })}
          iconProps={{ iconName: sidebarLink.iconName }}
          title={sidebarLink.label}
          ariaLabel={sidebarLink.label}
          onClick={() => handleClickSidebarLink(sidebarLink.path)}
        >
          <span
            className={classNames(
              'left-sidebar-link-label',
              isSidebarCollapsed ? '' : 'left-sidebar-link-label-visible',
            )}
          >
            {sidebarLink.label}
          </span>
        </IconButton>
      )),
    [activeTab, isSidebarCollapsed, handleClickSidebarLink],
  );

  return (
    <Stack className={styles}>
      <div className="left-sidebar-header">
        <AugmentLogo />
        <IconButton
          className="left-sidebar-header-button"
          iconProps={{
            iconName: isSidebarCollapsed ? 'ChevronRightMed' : 'ChevronLeftMed',
          }}
          onClick={() => toggleSidebarCollapse()}
        />
      </div>
      <div className="left-sidebar-links">{renderSidebarLinks}</div>
      <IconButton
        className="left-sidebar-back-icon"
        iconProps={{
          iconName: 'ClosePane',
        }}
        onClick={handleClickSidebarBackButton}
      />
    </Stack>
  );
};
