import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Bugsnag from '@bugsnag/js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useEffect, useLayoutEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useQuery } from 'react-query';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import AuthCallback from './Components/AuthCallback';
import { Redirect } from './Components/Common/Redirect';
import { DealRoomArtifactDetailView } from './Components/DealRoom/DealRoomArtifactDetailView';
import { DealRoomDetailView } from './Components/DealRoom/DealRoomDetailView';
import { DealRoomList } from './Components/DealRoom/DealRoomList';
import { DecisionSiteHome } from './Components/DecisionSite/DecisionSiteHome';
import { DecisionSiteBaseLayout } from './Components/Layouts/DecisionSiteBaseLayout';
import { MeetingPlanRoot } from './Components/MeetingPlans/MeetingPlanRoot';
import { NotFound } from './Components/NotFound';
import { OrganizationRoot } from './Components/Organization/OrganizationRoot';
import { LayoutTestPage } from './Components/Pages/LayoutTestPage';
import { Profile } from './Components/Profile';
import { StyledSpinner } from './Components/StyledSpinner';
import {
  isConsentRequiredError,
  isGenericError,
  isLoginRequiredError,
  isUnknownOrInvalidRefreshToken,
} from './Helpers/Auth0Helpers';
import { useInteractionMode } from './Hooks/useInteractionMode';
import { useLightOrDarkMode } from './Hooks/useLightOrDarkMode';
import { useLocalStorageState } from './Hooks/useLocalStorageState';
import { useOrganization } from './Hooks/useOrganization';
import { useUserProfile } from './Hooks/useProfile';
import { OrganizationsQuery } from './QueryNames';
import { OrganizationsApiClient } from './Services/NetworkCommon';
import { DealRoomBuyersRoot } from './Components/DealRoom/DealRoomBuyersRoot';

export type AppProps = {
  appInsights: ApplicationInsights;
};

export const App = ({ appInsights }: AppProps) => {
  const {
    user,
    isLoading: userLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, name, given_name, family_name, phone_number, sub } = user!;

  const { user: mfUser } = useUserProfile();

  const {
    organization,
    slug: organizationSlug,
    refetch: refetchOrg,
    forbidden: orgForbidden,
    role: orgRole,
    canCreatePlans,
    isGuest: isOrgGuest,
  } = useOrganization();

  // useLayoutEffect to set user context as early as possible
  useLayoutEffect(() => {
    if (!!email) {
      appInsights.setAuthenticatedUserContext(
        email.toLowerCase(),
        organization?.slug,
        true,
      );

      Bugsnag.setUser(mfUser?.id?.toString() || sub, email, name);
      Bugsnag.addMetadata('organization', {
        id: organization?.id,
        slug: organization?.slug,
      });
    }
  }, [
    appInsights,
    email,
    family_name,
    given_name,
    name,
    phone_number,
    sub,
    mfUser,
    organization,
  ]);

  const [lastSessionId, setLastSessionId] = useLocalStorageState<string>(
    'LAST_SESSION_ID',
    '',
  );
  const sessionId = appInsights.context.getSessionId();
  const { currentTheme } = useLightOrDarkMode();

  const [searchParams] = useSearchParams();

  const { supportsTouch } = useInteractionMode();

  const {
    user: userProfile,
    isLoading: userProfileLoading,
    isFetched: userProfileFetched,
    refetch: refetchUser,
  } = useUserProfile();

  const {
    data: orgsData,
    isLoading: orgsDataLoading,
    isFetched: orgsDataIsFetched,
    refetch: refetchOrgs,
  } = useQuery(OrganizationsQuery, async () => {
    const token = await getAccessTokenSilently();
    return OrganizationsApiClient.listOrganizations(
      {
        entitlement: 'DEAL_ROOM',
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  useEffect(() => {
    // On tab focus, try and get an Auth0 token silently
    // If the user has left the tab open for a prolonged period
    // The session may have expired, causing a login_required, or consent_required error
    // If so, trigger loginWithRedirect for interactive refresh to get new tokens
    const onFocus = () => {
      getAccessTokenSilently().catch((err: unknown) => {
        if (isLoginRequiredError(err) || isConsentRequiredError(err)) {
          loginWithRedirect();
        } else if (isUnknownOrInvalidRefreshToken(err)) {
          appInsights.trackEvent({ name: 'UNKNOWN_OR_INVALID_REFRESH_TOKEN' });
          loginWithRedirect();
        } else if (isGenericError(err)) {
          appInsights.trackEvent({
            name: 'AUTH0_GENERIC_ERROR',
            properties: {
              ...err,
            },
          });
          loginWithRedirect();
        } else {
          appInsights.trackException({
            exception: err instanceof Error ? err : new Error(`${err}`),
          });
          throw err;
        }
      });
    };

    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [appInsights, getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    getAccessTokenSilently().catch((err: unknown) => {
      if (isLoginRequiredError(err) || isConsentRequiredError(err)) {
        loginWithRedirect();
      } else if (isUnknownOrInvalidRefreshToken(err)) {
        appInsights.trackEvent({ name: 'UNKNOWN_OR_INVALID_REFRESH_TOKEN' });
        loginWithRedirect();
      } else if (isGenericError(err)) {
        appInsights.trackEvent({
          name: 'AUTH0_GENERIC_ERROR',
          properties: {
            ...err,
          },
        });
        loginWithRedirect();
      } else {
        appInsights.trackException({
          exception: err instanceof Error ? err : new Error(`${err}`),
        });
        throw err;
      }
    });
  }, [appInsights, getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    if (sessionId !== lastSessionId) {
      setLastSessionId(sessionId);
      appInsights.trackEvent({
        name: 'SESSION_START',
        properties: {
          sub: user?.sub,
          name: user?.name,
          email: user?.email,
          theme: currentTheme,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          nativeWidth: window.screen.width * window.devicePixelRatio,
          nativeHeight: window.screen.height * window.devicePixelRatio,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
          supportsTouch: !!supportsTouch,
          utmSource: searchParams.get('utm_source') ?? undefined,
        },
      });
    } else {
      appInsights.trackEvent({
        name: 'BROWSER_REFRESH',
        properties: {
          sub: user?.sub,
          name: user?.name,
          email: user?.email,
          theme: currentTheme,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          nativeWidth: window.screen.width * window.devicePixelRatio,
          nativeHeight: window.screen.height * window.devicePixelRatio,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
          supportsTouch: !!supportsTouch,
          utmSource: searchParams.get('utm_source') ?? undefined,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  const hasOrgs = !!orgsData?.data?.length;

  if (
    userLoading ||
    (userProfileLoading && !userProfileFetched) ||
    (orgsDataLoading && !orgsDataIsFetched)
  ) {
    return <StyledSpinner />;
  }

  return (
    <>
      <DecisionSiteBaseLayout>
        <div style={{ height: '100%' }}>
          <Routes>
            <Route path="/" element={<DecisionSiteHome />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route
              path="/organization/:organizationSlug"
              element={<OrganizationRoot />}
            >
              <Route index element={<DealRoomList />} />
              <Route
                path="dealroom/:dealRoomId"
                element={<DealRoomBuyersRoot />}
              >
                <Route
                  path="artifact/:artifactId"
                  element={<DealRoomArtifactDetailView />}
                />
                <Route index element={<DealRoomDetailView />} />
                <Route path="overview" element={<DealRoomDetailView />} />
                <Route path="artifacts" element={<DealRoomDetailView />} />
                <Route path="journey" element={<DealRoomDetailView />} />
                <Route
                  path="mutual-action-plan"
                  element={<DealRoomDetailView />}
                />
                <Route path="settings" element={<DealRoomDetailView />} />
                <Route
                  path="*"
                  element={
                    <Redirect to="/organization/:organizationSlug/dealroom/:dealRoomId" />
                  }
                />
              </Route>
              <Route path="plan/:meetingPlanId" element={<MeetingPlanRoot />} />
              <Route
                path="*"
                element={<Redirect to="/organization/:organizationSlug" />}
              />
            </Route>
            {import.meta.env.DEV ? (
              <Route path="/layout-test" element={<LayoutTestPage />} />
            ) : null}

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Toaster />
      </DecisionSiteBaseLayout>
    </>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <StyledSpinner />,
});
